<template>
  <div>
    <b-card>
      <b-input-group class="mb-3">
        <b-input-group-prepend>
          <b-button variant="primary" @click="list" :disabled="busy.list">
            <i class="fa fa-search"></i> 검색
            <b-spinner class="mr-1" small v-if="busy.list"></b-spinner>
          </b-button>
        </b-input-group-prepend>
        <b-form-input type="text" placeholder="goods_no, goods_id, sku_id를 넣어주세요" v-model="form.search" @keypress.enter="list" v-focus></b-form-input>
      </b-input-group>
      <shop-preset :shop_type="true" v-model="form.shop"></shop-preset>
      <div class="mb-2">
        <b-form-checkbox v-model="form.real_shop">특수 SHOP (0. 개인결제창, 204. CLEARANCE SALE, 2806. BALAAN REPAIR) 을 제외합니다</b-form-checkbox>
      </div>

      <b-row class="mb-2">
        <b-col cols="6" lg="4">
          <div class="fs-11">최근 상품프로세싱 이력</div>
          <b-form-select class="col-form-label" v-model="form.process_log" :options="[{text:'선택해주세요', value:'', disabled:true}, ...process_log]"
                         @change="setProcessLog"></b-form-select>
        </b-col>
        <b-col v-if="$R('GOODS_R')" cols="6" lg="4">
          <div class="fs-11">등록상태</div>
          <b-form-radio-group class="col-form-label" v-model="form.goods_status" :options="[
            {text: '전체', value: 'ALL'},
            {text: 'Processing', value: 'processing'},
            {text: 'Registered', value: 'registered'},
            {text: 'Terminated', value: 'terminated'}
          ]"></b-form-radio-group>
        </b-col>
        <b-col cols="6" lg="2">
          <div class="fs-11">재고상태</div>
          <b-form-radio-group class="col-form-label" v-model="form.stock_status" :options="[
            {text: '전체', value: 'ALL'},
            {text: '재고있음', value: 'normal'},
            {text: '품절', value: 'runout'}
          ]"></b-form-radio-group>
        </b-col>
        <b-col v-if="$R('GOODS_R')" cols="6" lg="2">
          <div class="fs-11">노출상태</div>
          <b-form-radio-group class="col-form-label" v-model="form.display_status" :options="[
            {text: '전체', value: 'ALL'},
            {text: '노출', value: 'view'},
            {text: '미노출', value: 'notview'}
          ]"></b-form-radio-group>
        </b-col>
        <b-col md="6">
          <div><small class="mb-n2">상품유형</small></div>
          <b-form inline>
            <b-form-radio-group class="col-form-label" v-model="form.goodsType" :options="[
                {text: '전체', value: 'ALL'},
                {text: '새상품만', value: 'new'},
                {text: '빈티지만', value: 'used'}
              ]"></b-form-radio-group>
            <template v-if="form.goodsType === 'used'">
              <b-button class="mr-1" size="sm" variant="light" @click="toggleUsedGrade()">전체</b-button>
              <b-button class="mr-1" size="sm" variant="primary" @click="toggleUsedGrade('S')">S</b-button>
              <b-button class="mr-1" size="sm" variant="success" @click="toggleUsedGrade('A')">A</b-button>
              <b-button class="mr-2" size="sm" variant="warning" @click="toggleUsedGrade('B')">B</b-button>
              <b-form-checkbox-group v-model="form.usedGrade">
                <b-form-checkbox v-for="s in $C.USED_GRADE" :key="s.value" :value="s.value" :title="s.desc">{{ s.text }}</b-form-checkbox>
              </b-form-checkbox-group>
            </template>
          </b-form>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" lg="6">
          <brand-preset class="mb-3" v-model="form.brand" :hideDisabled="true"></brand-preset>
        </b-col>
        <b-col cols="12" lg="6">
          <category-preset class="mb-3" v-model="form.category"></category-preset>
        </b-col>
      </b-row>

      <b-collapse class="mt-2" id="collapse1">
        <b-row class="mb-3">
          <b-col md="4">
            <div class="fs-11">생성시각</div>
            <b-form inline>
              <b-form-input class="text-center" v-model="form.created_from" placeholder="2020-04-01 00:00:00"></b-form-input>
              ~
              <b-form-input class="text-center" v-model="form.created_to" placeholder="2020-04-01 00:00:00"></b-form-input>
            </b-form>
          </b-col>
          <b-col md="4">
            <div class="fs-11">최근수정시각</div>
            <b-form inline>
              <b-form-input class="text-center" v-model="form.updated_from" placeholder="2020-04-01 00:00:00"></b-form-input>
              ~
              <b-form-input class="text-center" v-model="form.updated_to" placeholder="2020-04-01 00:00:00"></b-form-input>
            </b-form>
          </b-col>
          <b-col md="4">
            <div class="fs-11">goods_no 범위선택</div>
            <b-form inline>
              <b-form-input class="text-center" v-model.number="form.goods_no_from" placeholder="시작"></b-form-input>
              ~
              <b-form-input class="text-center" v-model.number="form.goods_no_to" placeholder="끝"></b-form-input>
            </b-form>
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col lg="4">
            <div class="fs-11">goods_no</div>
            <b-form-textarea id="goodsNo" :rows="2" v-model="form.goods_no" placeholder="goods_no를 입력해주세요"></b-form-textarea>
          </b-col>
          <b-col lg="4">
            <div class="fs-11">제외할 goods_no</div>
            <b-form-textarea id="goodsNoExclude" :rows="2" v-model="form.goods_no_exclude" placeholder="제외할 goods_no를 입력해주세요"></b-form-textarea>
          </b-col>
          <b-col lg="4">
            <div class="fs-11">한 번에 가져올 상품 수</div>
            <b-form-input id="limit" type="text" placeholder="한 번에 가져올 상품 수" v-model.number="form.limit"></b-form-input>
          </b-col>
        </b-row>
      </b-collapse>
      <b-button class="m-1" variant="primary" @click="list" :disabled="busy.list">
        <b-spinner class="mr-1" small v-if="busy.list"></b-spinner>
        검색
      </b-button>
      <b-button class="m-1" variant="outline-success" v-b-toggle.collapse1>상세검색조건</b-button>
    </b-card>

    <b-alert show variant="success">shift 키를 누르고 드래그하면 상품을 추가할 수 있습니다. click하면 기존 선택에 추가로 선택됩니다</b-alert>

    <b-card>
      <b-row class="mb-2">
        <b-col cols="6">
          <small>현재상품 카테고리별 선택(최종 카테고리가 아니라면 노란색, W: 여성, M: 남성, G: 골프, B: 뷰티, L: 리빙)</small><br/>
          <b-row class="mb-2">
            <b-col v-for="n in [2,3,4]" :key="n">
              <template v-if="n === 2">
                <b-button variant="primary" @click="setFilteredList()"> 전체: {{ items.list.length }}</b-button>
                <br/>
                <small>1차 카테고리</small><br/>
                <b-button variant="outline-primary" size="sm" @click="setFilteredList(1)"> 전체: {{ cateLenMap[1] ? cateLenMap[1].length : 0 }}</b-button>
                <perfect-scrollbar class="mt-1" style="min-height:30px;max-height:280px;overflow-y:auto">
                  <b-button :variant="categoryMap[k].final ? 'light' : 'warning'" size="sm" class="mb-1 mr-1" :title="categoryMap[k].full_nm"
                            v-for="[k,v] in Object.entries(cateGoodsMap[1])" @click="setFilteredList(k)" :key="k">{{ categoryMap[k].nm }}: {{ v.length }}
                  </b-button>
                </perfect-scrollbar>
              </template>
              <small>{{ n }}차 카테고리</small><br/>
              <b-button variant="outline-primary" size="sm" @click="setFilteredList(n)"> 전체: {{ cateLenMap[n] ? cateLenMap[n].length : 0 }}</b-button>
              <perfect-scrollbar class="mt-1" style="max-height:300px;overflow-y:auto">
                <b-button :variant="categoryMap[k].final ? 'light' : 'warning'" size="sm" class="mb-1 mr-1" :title="categoryMap[k].full_nm"
                          v-for="[k,v] in Object.entries(cateGoodsMap[n])" @click="setFilteredList(k)" :key="k"><b>{{categoryMap[k].initial}}</b> {{ categoryMap[k].nm }}: {{ v.length }}
                </b-button>
              </perfect-scrollbar>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="b-l-1" cols="6">
          <small>카테고리 지정 및 저장(끝의 * 표시는 최종카테고리를, → 표시는 하위 카테고리가 있음을 의미합니다)</small><br/>
          <div class="clearfix">
            <b-button-group>
              <b-button v-for="c in firstCategory" :variant="selectedCate.startsWith(c.category) ? 'dark' : 'light'" @click="cateSelect(c.category)"
                        :key="c.category">
                {{ c.category_nm }}
              </b-button>
            </b-button-group>
          </div>
          <b-row class="mb-2">
            <b-col>
              <small>2차 카테고리</small>
              <b-form-select :select-size="10" v-model="inputCate[1]" :options="selectedCate.length > 0 ? cate[1] : cateNoGender"
                             @change="cateSelect"></b-form-select>
            </b-col>
            <b-col>
              <small>3차 카테고리</small>
              <b-form-select :select-size="10" v-model="inputCate[2]" :options="cate[2]" @change="cateSelect"></b-form-select>
            </b-col>
            <b-col>
              <small>4차 카테고리</small>
              <b-form-select :select-size="10" v-model="inputCate[3]" :options="cate[3]" @change="cateSelect"></b-form-select>
            </b-col>
            <b-col>
              <small>5차 카테고리</small>
              <b-form-select :select-size="10" v-model="selectedSubname" :options="selectedSubnameList" @change="subNameSelect"></b-form-select>
            </b-col>
          </b-row>

          <v-select v-model="selectedKeyword" :options="keyword" :placeholder="'키워드 선택'" @input="selectKeyword"></v-select>

          <div class="mt-2 clearfix">
            <div class="pull-left col-form-label">
              {{ items.filteredList.filter(e => e.selected).length }} 개 상품이 선택됨
            </div>
            <div class="pull-right">
              <span class="font-weight-bold">[ {{ cateName }} ]</span>
              카테고리로
              <b-button variant="success" @click="saveCate" :disabled="busy.saveCate">
                <b-spinner class="mr-1" small v-if="busy.saveCate"></b-spinner>
                저장
              </b-button>
              <b-button v-if="selectedCate" variant="outline-primary" @click="searchCategory">검색</b-button>
              <b-button v-if="selectedCate" variant="outline-success" @click="googleCategory">구글</b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <div class="clearfix">
        <div class="pull-right">
          <b-button variant="primary" @click="selectItems(true)">전체선택</b-button>
          <b-button variant="warning" @click="selectItems(false)">선택해제</b-button>
        </div>
        <b-form inline>
          <b-button-group>
            <b-button :variant="picGroup === 'ALL' ? 'primary' : 'light'" @click="picGroup = 'ALL'">
              {{ items.filteredList.length }} 개 전체 아이템
            </b-button>
            <b-button :variant="picGroup === 'selected' ? 'success' : 'light'" @click="picGroup = 'selected'">
              {{ items.filteredList.filter(e => e.selected).length }} 개 선택된 아이템
            </b-button>
          </b-button-group>
          <b-input-group class="ml-1">
            <b-input-group-prepend>
              <b-input-group-text>
                <i class="fa fa-filter"></i>
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input v-model="picFilter"></b-form-input>
            <b-input-group-append v-if="picFilter">
              <b-input-group-text @click="picFilter=``">
                <i class='text-danger fa fa-close'></i>
              </b-input-group-text>
            </b-input-group-append>
            <b-input-group-append v-if="picFilter">
              <b-input-group-text>
                {{ picFilteredCnt }} 개
              </b-input-group-text>
            </b-input-group-append>
          </b-input-group>
          <b-input-group class="ml-1">
            <b-input-group-prepend>
              <b-input-group-text>이미지 수</b-input-group-text>
            </b-input-group-prepend>
            <b-input-group-append>
              <b-button @click="picWidth=126">12</b-button>
            </b-input-group-append>
            <b-input-group-append>
              <b-button @click="picWidth=140">11</b-button>
            </b-input-group-append>
            <b-input-group-append>
              <b-button @click="picWidth=154">10</b-button>
            </b-input-group-append>
            <b-input-group-append>
              <b-button @click="picWidth=172">9</b-button>
            </b-input-group-append>
            <b-input-group-append>
              <b-button @click="picWidth=195">8</b-button>
            </b-input-group-append>
            <b-input-group-append>
              <b-button @click="picWidth=223">7</b-button>
            </b-input-group-append>
            <b-input-group-append>
              <b-button @click="picWidth=263">6</b-button>
            </b-input-group-append>
            <b-input-group-append>
              <b-button @click="picWidth=316">5</b-button>
            </b-input-group-append>
            <b-input-group-append>
              <b-button @click="picWidth=398">4</b-button>
            </b-input-group-append>
            <b-form-input class="w-65px text-center" type="number" v-model.number="picWidth"></b-form-input>
            <b-input-group-append>
              <b-input-group-text>px</b-input-group-text>
            </b-input-group-append>
          </b-input-group>
          <!--<b-checkbox class="ml-2" v-model="picInfoTop">상단요약</b-checkbox>-->
          <b-checkbox class="ml-2" v-model="picInfoBottom">하단정보</b-checkbox>
        </b-form>
      </div>

      <drag-select ref="drag" class="flex-row flex-wrap d-flex" attribute="attr" selectorClass="itemToBeSelected" @change="dragSelectItems">
        <div v-for="e of items.filteredList"
             v-if="(picFilter ? e.filtered : true) && (picGroup === 'ALL' || e.selected)"
             :key="e.goods_no" :attr="e.goods_no"
             :selected="e.selected ? 1 : 0"
             class="flex-grow-0 m-1 position-relative itemToBeSelected"
             :style="{width:picWidth+'px', padding:'3px', border:e.selected ? '3px solid #20a8d8' : '3px solid #f8f8f8'}"
             @click="clickItem($event, e)">
          <div class="position-absolute text-right" style="right:0;line-height:15px">
            <template v-if="e.manual">
              <b-badge variant="danger">파트너관리</b-badge>
              <br/>
            </template>
            <a :href="`/#/goods/${e.goods_no}?goods_status=ALL`" target="_blank" :title="e.goods_no" class="badge badge-outline-primary" @click.stop="">
              <i class="fa fa-external-link"></i>
            </a>
            <div v-if="picInfoTop">
            </div>
          </div>

          <img :src="$utils.getImageSrc(e, 0, 'thumb_300')" class="w-100" :data-goods_no="e.goods_no" @dragstart.prevent=""
               @mouseover="rotateImage($event, e)" @mouseout="stopRotate($event, e)"/>

          <div v-if="picInfoBottom" class="info-bottom" style="line-height:18px">
            <template>
              <div :class="`text-truncate overflow-hidden ${picWidth < 250 ? 'fs-11 ' : ''}bold`" :title="e.goods_nm">{{ e.goods_nm }}</div>
              <div :class="`text-truncate overflow-hidden ${picWidth < 250 ? 'fs-11 ' : ''}`" :title="e.org_name">{{ e.org_name }}</div>
              <div :class="`text-truncate overflow-hidden badge badge-light ${picWidth < 250 ? 'fs-11 ' : ''}`" :title="e.category_nm">{{ e.category_nm }}
              </div>
              <div :class="`overflow-hidden ${picWidth < 250 ? 'fs-11 ' : ''}`" style="max-height:75px">
                <b-badge>{{ e.options[0] && e.options[0].optnm || '' }}({{ e.options.filter(e => !e.not_found).length }}개)</b-badge>
                <b-badge v-for="opt of e.options.filter(e => !e.not_found)" variant="light" :key="opt.Size">{{ opt.Size }}</b-badge>
              </div>
            </template>
          </div>
        </div>
      </drag-select>
    </b-card>
  </div>
</template>

<script>
import DragSelect from '@/views/modules/DragSelect.vue'
import Sticky from 'vue-sticky-directive'

export default {
  name: 'GoodsInfo',
  title: '상품정보개선',
  components: {DragSelect},
  directives: {Sticky},
  data() {
    return {
      shop: [],
      shopMap: {},
      brand: [],
      brandMap: {},
      category: [],
      categoryMap: {},
      firstCategory: [],
      godoCols: [],
      form: {
        search: '',
        shop: [],
        brand: [],
        category: [],
        real_shop: true,
        goods_status: 'registered',
        stock_status: 'ALL',
        display_status: 'ALL',
        goodsType: 'ALL',
        usedGrade: this.$C.USED_GRADE.map(e => e.value),
        limit: 100,
        skip: 0,
        process_log: "",
        created_from: '',
        created_to: '',
        updated_from: '',
        updated_to: '',
        goods_no_from: '',
        goods_no_to: '',
        goods_no: '',
        goods_no_exclude: '',
      },
      process_log: [],
      lastBody: {list: {}},
      items: {list: [], filteredList: []},
      itemMap: {list: {}},
      busy: {list: false, listmore: false, stockPick: false, syncStock: false, updatePriceTable: false},
      hasMore: {list: false},
      ac: {list: null}, // abortController

      picFilter: '',
      picFilteredCnt: 0,
      picGroup: 'ALL',
      picWidth: 195,
      picInfoTop: true,
      picInfoBottom: true,
      modal: {detail: false},
      diff: null,
      priceHtml: '',
      selected: [],
      selectStart: false,
      dragShift: false,

      cateLenMap: {},
      cateGoodsMap: {1: {}, 2: {}, 3: {}, 4: {}},
      lastFilter: '',

      cate: [[], [], [], [], []],
      cateNoGender: [{text: '성별을 선택해주세요', value: null, disabled: true},],
      cateNoOpt: [{text: '상위 카테고리를 선택해주세요', value: null, disabled: true},],
      selectedCate: '',
      inputCate: ['', '', '', '', ''],
      cateName: '미정',
      keyword: [], // 스피드러너 등 카테고리 개념을 포함한 키워드
      keywordMap: {},
      subNameMap: {},
      selectedSubnameList: [],
      selectedSubname: '',
      selectedKeyword: null,
      rotateHandler: null,
      processingHistory: null,
      startIndex: 0
    }
  },
  async created() {
    this.$utils.getStatus(this.$options.name, this, 'picWidth');

    let meta = await this.$api.getMeta('shop,brand,category');
    if (!meta) return;

    this.shop = meta.shop.filter(e => e.use_yn === 'y').sort((a, b) => a.shop_id - b.shop_id);
    this.shop.forEach(s => {
      s.value = s.boutique;
      s.label = `${s.shop_id}. ${s.boutique}`;
      this.shopMap[s.shop_id] = s;
    });

    this.brand = meta.brand.map(e => {
      return this.brandMap[e.brand_no] = {...e, value: e.brand_no, label: `${e.brand_nm} (${e.brand_nm_kr})`};
    }).sort((a, b) => a.label.localeCompare(b.label));

    const firstCodeMap = {'009': 'W', '010': 'M', '011': 'G', '020': 'K', '030': 'L', '040': 'S', '050': 'D', '060': 'P', '070': 'A'};
    this.category = meta.category.map(e => {
      if (e.category.length === 3) this.firstCategory.push(e);
      return this.categoryMap[e.category] = {
        ...e,
        value: e.category,
        parent: e.category.substring(0, e.category.length - 3),
        nm: `${e.category_nm}${e.final ? '*' : ''}`,
        label: `${e.category} (${e.category_nm})`,
        initial: firstCodeMap[e.category.slice(0, 3)],
      };
    }).sort((a, b) => (a.value.length - b.value.length) * 10 + a.value.localeCompare(b.value));
    // 한번 더 루프를 돌아 full name 을 확보한다.
    this.category.forEach(e => {
      e.full_nm = Array(e.category.length / 3).fill(0).map((n, i) => this.categoryMap[e.category.substring(0, i * 3 + 3)].category_nm).join(' > ');
    });

    if (Object.keys(this.$route.query).length) {
      Object.keys(this.form).forEach(k => {
        if (this.$route.query[k] != null) {
          this.form[k] = this.$route.query[k];
        }
      });
    }

    await this.getProcessLog();
    await this.getKeyword();
    this.list();
  },
  async beforeDestroy() {
    Object.values(this.ac).filter(e => e).forEach(e => e.abort());
    this.$utils.setStatus(this.$options.name, this, 'picWidth');
  },
  watch: {
    picFilter(v) {
      if (v) {
        this.items.filteredList.forEach(e => {
          let uv = v.toUpperCase();
          e.filtered = ~e.goods_nm.toUpperCase().indexOf(uv)
            || ~e.sku_id.toUpperCase().indexOf(uv)
            || ~(e.goods_id + '').toUpperCase().indexOf(uv)
            || ~(e.goods_no + '').toUpperCase().indexOf(uv)
            || ~(e.org_name || '').toUpperCase().indexOf(uv)
            || ~(e.brand_nm || '').toUpperCase().indexOf(uv)
            || ~(e.brand_nm_kr || '').toUpperCase().indexOf(uv)
            || ~(e.category_nm || '').toUpperCase().indexOf(uv)
        });
        this.picFilteredCnt = this.items.filteredList.filter(e => e.filtered && (this.picGroup === 'ALL' || e.selected)).length;
      }
    },
    picGroup(v) {
      this.picFilteredCnt = this.items.filteredList.filter(e => e.filtered && (v === 'ALL' || e.selected)).length;
    },
    picWidth() {
      this.$utils.setStatus(this.$options.name, this, 'picWidth');
    },
  },
  methods: {
    async list(more) {
      let form = this.form;
      if (form.limit > 500) {
        return this.$utils.alert('한 번에 가져올 상품 수는 500개 이하로 설정해주세요');
      }
      let shop = form.shop.length === this.shop.length ? [] : form.shop.map(e => e.shop_id); // 전체 선택일 경우 비우기
      let brand = form.brand.map(e => e.value);
      let category = form.category.map(e => e.value);
      let goods_no = this.form.goods_no.trim() ? this.form.goods_no.trim().split(/\D+/g).map(e => +e) : [];
      let goods_no_exclude = this.form.goods_no_exclude.trim() ? this.form.goods_no_exclude.trim().split(/\D+/g).map(e => +e) : [];
      if (goods_no.length) {
        goods_no = goods_no.filter(e => !~goods_no_exclude.indexOf(e));
        goods_no_exclude = [];
      }

      let body = {
        ...form, shop, brand, category, goods_no_include: goods_no, goods_no_exclude,
      };
      await this.$api.postTable(this, '/goods/confirmed', body, {
        more, fnAssign: e => {
          e.cateLen = e.category.length / 3
        }
      });

      this.cateLenMap = this.$utils.arr2multi(this.items.list, 'cateLen');
      '1,2,3,4'.split(',').forEach(n => {
        this.cateGoodsMap[n] = this.$utils.arr2multi(this.cateLenMap[n] || [], 'category');
      });
      this.itemMap.list = this.$utils.arr2map(this.items.list, 'goods_no');
      this.setFilteredList();
    },
    toggleUsedGrade(grade) {
      if (!grade) {
        this.form.usedGrade = this.form.usedGrade.length === this.$C.USED_GRADE.length ? [] : this.$C.USED_GRADE.map(e => e.value);
      } else {
        this.form.usedGrade = this.$C.USED_GRADE.filter(e => e.value[0] === grade).map(e => e.value);
      }
    },
    /**
     * 카테고리 버튼을 선택할 때 호출된다.
     */
    setFilteredList(cate) {
      this.lastFilter = cate;
      if (typeof (cate) === 'number') {
        this.items.filteredList = this.items.list.filter(e => e.cateLen === cate);
      } else if (cate) {
        this.items.filteredList = this.items.list.filter(e => e.category === cate);
        this.cateSelect(cate);
      } else {
        this.items.filteredList = this.items.list;
      }

      this.selected = [];
      this.items.filteredList.forEach(e => e.selected = false);

      if (!this.$refs.drag) return; // 로딩중 다른메뉴 이동

      let divs = Array.from(this.$refs.drag.$el.children);
      divs.forEach(e => {
        e.setAttribute('attr', e.getAttribute('attr'));
      })
      this.$refs.drag.selectItems('unselect', divs);
      this.$forceUpdate();
    },
    async getProcessLog() {
      let j = await this.$api.getJson('/goods/processLog');
      if (j) {
        this.process_log = j.list.filter(e => {
          let flag = true;
          if (!e.ok) flag = false;
          for (const shop_id of e.shop_ids) {
            if (!this.shopMap[shop_id]) {
              flag = false;
              break;
            }
          }
          return flag;
        }).map(e => ({text: `[${e._dt}] ${e.shop_ids[0]}. ${this.shopMap[e.shop_ids[0]].boutique} : ${e.goods_nos.length}개 (${e._name})`, value: e}));
      }
    },
    async setProcessLog(e) {
      this.processingHistory = e;

      const CHUNK = 1000;
      this.form.goods_no = e.goods_nos.join(',');
      this.form.limit = e.goods_nos.length > CHUNK ? CHUNK : e.goods_nos.length;
      this.list();
    },
    async getKeyword() {
      let j = await this.$api.getJson('/goods/cateKeywords');
      if (j) {
        this.keyword = j.list.map(e => {
          let cate = this.categoryMap[e.category];
          return cate && {...cate, subname: e.subname, label: `${e.category} (${cate.full_nm} > ${e.subname})`};
        }).filter(e => e);
        this.keywordMap = this.$utils.arr2multi(this.keyword, 'subname');
        // this.subNameMap = this.$utils.arr2multi(this.keyword.filter(e=>e.subname), 'category', 'subname');
        j.list.forEach(e => {
          if (!this.subNameMap[e.category]) this.subNameMap[e.category] = [];
          if (e.subname) this.subNameMap[e.category].push(e.subname);
        });
      }
    },
    async selectKeyword(e) {
      console.log(e);
      if (!e) return;
      this.cateSelect(e.category);
      this.subNameSelect(e.subname);
    },
    picClick(e, shift) {
      // e.selected = !e.selected;
      // console.log('picClick', e.goods_no)
      // this.$forceUpdate();
    },
    recalcPicFilteredCnt() {
      this.picFilteredCnt = this.items.filteredList.filter(e => e.filtered && (this.picGroup === 'ALL' || e.selected)).length;
    },
    setSelect(divs) {
      this.selectStart = true;
      this.selected = divs.map(e => e.getAttribute('attr'));
      let selectMap = {};
      this.selected.forEach(e => selectMap[e] = true);
      this.items.filteredList.forEach(e => e.selected = !!selectMap[e.goods_no]);
      // console.log('setSelect', divs.length, this.selected);
      // console.log(e, this.dragShift, this.itemMap);
      // if (!this.dragShift) { // 추가모드가 아닐 땐 기존선택 취소
      //   this.items.filteredList.forEach(item=>item.selected = false);
      // }
      // e.forEach(no=>{
      //   this.itemMap.list[no].selected = true;
      // });
      // this.dragShift = false;
      this.$forceUpdate();
    },
    setShift() {
      // this.dragShift = true;
    },
    clickItem(e, item) {
      // path 중에서 classList 에 itemToBeSelected 가 있는 div 를 찾는다.
      // for (let el of e.path) {
      //   if (~el.className.indexOf('itemToBeSelected')) {
      //     this.$refs.drag.clickItem(el);
      //     break;
      //   }
      // }
      // this.$forceUpdate();
      item.selected = !item.selected;
      this.$forceUpdate();
    },
    dragSelectItems(divs) {
      const selectedMap = {};
      this.items.filteredList.forEach(e => selectedMap[e.goods_no] = e.selected);
      divs.map(e => e.getAttribute('attr')).forEach(e => selectedMap[e] = true);
      this.items.filteredList.forEach(e => e.selected = !!selectedMap[e.goods_no]);

      this.$forceUpdate();
    },
    selectItems(mod) {
      this.items.filteredList.filter(e=>(this.picFilter ? e.filtered : true) && (this.picGroup === 'ALL' || e.selected)).forEach(e=>e.selected = mod);
      this.recalcPicFilteredCnt();
      this.$forceUpdate()
    },
    /**
     * 카테고리 multiselect를 선택할 때 호출된다.
     * 해당 카테고리의 하위 카테고리를 갱신한다.
     *
     * @param {string} category
     */
    cateSelect(category, f) {
      console.log(category, this.selectedKeyword);
      if (!category) return;
      this.selectedCate = category;
      this.selectedSubnameList = this.subNameMap[category] || [];
      this.selectedCategory = this.categoryMap[category];
      let n = this.selectedCate.length / 3;
      // 카테고리 계층 표시
      let names = [];
      for (let i = 0; i < n; i++) {
        let subCate = category.substring(0, (i + 1) * 3);
        this.cate[i + 1] = this.category.filter(e => e.category.startsWith(subCate) && e.category.length === subCate.length + 3)
          .map(e => ({text: `${e.category_nm} ${e.final ? '*' : '→'}`, value: e.category, subname: e.subname}));
        names.push(this.categoryMap[subCate].category_nm);
        this.inputCate[i] = subCate;
      }

      if (!this.selectedSubnameList.length) this.selectedSubname = '';
      // 이후 카테고리는 초기화
      for (let i = n + 1; i < 4; i++) {
        this.cate[i] = [];
      }
      for (let i = n; i < 5; i++) {
        this.inputCate[i] = '';
      }
      this.cateName = names.join(' > ') + `(${this.selectedCate})`;
      if (this.selectedKeyword && this.selectedKeyword.category !== this.selectedCate) {
        this.selectedKeyword = null;
      }
    },
    subNameSelect(e) {
      this.selectedSubname = e;
      const names = [];
      for (let i = 0; i < 5; i++) {
        let subCate = this.selectedCate.substring(0, (i + 1) * 3);
        if (!subCate) break;
        names.push(this.categoryMap[subCate].category_nm);
      }
      names.push(e);
      this.cateName = names.join(' > ') + `(${this.selectedCate})`;
    },
    resetCate() {
      this.selectedCate = '';
      this.cateName = '';
      this.cate = [[], [], [], [], []];
      this.selectedSubnameList = [];
    },
    /**
     * 주어진 카테고리명을 이미지로 검색한다.
     */
    searchCategory() {
      this.$utils.open(`/#/goods?$shop=${this.items.list[0].shop_id}&$category=${this.selectedCate}&itemMode=pic&goods_status=ALL&collapse.detail=1`);
    },
    googleCategory() {
      let nm = this.categoryMap[this.selectedCate].category_nm;
      this.$utils.open(`https://www.google.com/search?q=${encodeURIComponent(nm)}&tbm=isch`);
    },
    async saveCate() {
      let selected = this.items.filteredList.filter(e => e.selected);
      if (!selected.length) return this.$utils.alert('선택된 아이템이 없습니다');
      let cate = this.categoryMap[this.selectedCate];
      if (cate.category.length <= 6 && !confirm('정말로 2차 이하의 카테고리로 저장하시겠습니까?')) return;
      const keyword = this.selectedKeyword ? this.selectedKeyword.subname : this.selectedSubname;
      if (!confirm(`저장하시겠습니까?`)) return;
      this.busy.saveCate = true;
      // 카테고리 계층 표시
      let names = [];
      let n = this.selectedCate.length / 3;
      for (let i = 0; i < n; i++) {
        let subCate = cate.category.substring(0, (i + 1) * 3);
        names.push(this.categoryMap[subCate].category_nm);
      }

      let j = await this.$api.postJson('/goods/saveCate', {
        goods_nos: selected.map(e => e.goods_no),
        category: cate.category,
        category_nm: cate.category_nm,
        keyword,
        names
      });
      this.busy.saveCate = false;
      if (j) {
        j.docs.forEach(e => {
          let item = this.itemMap.list[e.goods_no];
          item.category = e.category;
          item.category_nm = e.category_nm;
          item.goods_nm = e.goods_nm;
          item.cateLen = e.category.length / 3;
        });

        this.cateLenMap = this.$utils.arr2multi(this.items.list, 'cateLen');
        '1,2,3,4'.split(',').forEach(n => {
          this.cateGoodsMap[n] = this.$utils.arr2multi(this.cateLenMap[n] || [], 'category');
        });
        this.setFilteredList(this.selectedCate);

        this.$alertTop(`${selected.length} 개 아이템이 저장되었습니다`);
      }
    },
    rotateImage($event, e) {
      let i = 0, img = $event.srcElement;
      this.rotateHandler = setInterval(() => {
        img.src = this.$utils.getImageSrc(e, i++, 'thumb_300');
      }, 500);
    },
    stopRotate($event, e) {
      clearInterval(this.rotateHandler);
      $event.srcElement.src = this.$utils.getImageSrc(e, 0, 'thumb_300');
    },
  }
}
</script>
